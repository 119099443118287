import React, { useState, useContext } from 'react';
import styled from '@emotion/styled';
import * as Yup from 'yup';
import { withTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { toast } from 'react-toastify';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from '@mui/material';
import StyledDropzone from '../../../../../../components/StyledDropzone';
import Loading from '../../../../../../components/Loading';
import UnitSelectAsync from '../../../../../../components/UnitSelectAsync';
import { ReportContext } from '../../ReportGeneral/components/ReportProvider';
import axios from '../../../../../../utils/axios';
import useAuth from '../../../../../../hooks/useAuth';

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const TextError = styled.p`
  color: ${({ theme }) => theme.colors.error};
  font-size: 12px;
  margin-top: 3px;
  margin-left: 3px;
`;

function ModalUpload({ open, setOpen, t }) {
  const initValues = {
    version: null,
    url: '',
  };

  const [loading, setLoading] = useState(false);
  const [isMargin, setIsMargin] = useState(true);
  const [dataFile, setDataFile] = useState({});
  const [initialValues, setInitialValues] = useState(initValues);
  const { user } = useAuth();

  const validationSchema = Yup.object().shape({
    version: Yup.object().shape({
      key: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
      label: Yup.string().required(t('Campo obrigatório', { ns: 'maxion' })),
    }),
    url: Yup.string().required(`${t('Campo obrigatório', { ns: 'maxion' })}!`),
  });

  async function handleSubmit(
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) {
    setLoading(true);

    dataFile.append('version_id', values.version.key);
    dataFile.append('user_id', user.id);

    await axios
      .post(
        `${process.env.REACT_APP_PUBLIC_API}upload_file/pricing-excel`,
        dataFile,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then((response) => {
        console.log('response', response);
        resetForm();
        toast.info(response.data.message);
        setOpen(false);
      })
      .catch((error) => console.log('Error when uploading', error))
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClose(resetForm) {
    setOpen(false);
    resetForm();
    setInitialValues(initValues);
  }

  const {
    isLoadingYears,
    dataYears,
    isLoadingParameters,
    dataParameters,
    versionYears,
  } = useContext(ReportContext);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      enableReinitialize={true}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
        handleReset,
        isSubmitting,
      }) => (
        <Dialog
          open={open}
          onClose={() => handleClose(handleReset)}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>{t('Pricing', { ns: 'maxion' })}</DialogTitle>

          <DialogContent>
            <Form>
              <div className="item" style={{ marginBottom: 20 }}>
                <p>{t('Ano do Plano', { ns: 'maxion' })}*</p>
                <div>
                  <UnitSelectAsync
                    limitTags={0}
                    size="small"
                    name="plan_year"
                    value={values.plan_year}
                    label={t('Ano do Plano', { ns: 'maxion' })}
                    loading={isLoadingYears}
                    disabled={isLoadingYears}
                    loadingText={t('Carregando', { ns: 'maxion' })}
                    options={versionYears ? versionYears : []}
                    getOptionLabel={(option) => option.label}
                    onBlur={handleBlur}
                    onChange={(event, value) =>
                      setFieldValue('plan_year', value)
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.key === value.key
                    }
                    disableCloseOnSelect
                  />
                </div>
              </div>
              <div className="item">
                <p>{t('Versões', { ns: 'maxion' })}</p>
                <div>
                  <UnitSelectAsync
                    limitTags={0}
                    size="small"
                    name="version"
                    value={values.version}
                    label={t('Versão', { ns: 'maxion' })}
                    loading={isLoadingParameters}
                    disabled={isLoadingParameters}
                    loadingText={t('Carregando', { ns: 'maxion' })}
                    options={
                      dataParameters.versions ? dataParameters.versions : []
                    }
                    getOptionLabel={(option) => option.label}
                    onBlur={handleBlur}
                    onChange={(event, value) => setFieldValue('version', value)}
                    isOptionEqualToValue={(option, value) =>
                      option.key === value.key
                    }
                    disableCloseOnSelect
                    error={errors && errors.version}
                  />
                </div>
              </div>
              <StyledDropzone
                isEditing={false}
                setFieldValue={setFieldValue}
                setFormData={setDataFile}
                setIsMargin={setIsMargin}
                accept={'excel'}
              />
              <TextField
                style={{ marginTop: 20 }}
                margin="dense"
                id="url"
                label={t('Arquivo Pricing', { ns: 'maxion' })}
                type="text"
                disabled
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.url}
                fullWidth
                variant="outlined"
                error={values.url === '' && Boolean(errors.url)}
              />

              {errors.url && <TextError>{errors.url}</TextError>}

              {/*<pre>{JSON.stringify(dataFile, null, 4)}</pre>*/}
              {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
              {/*<pre>{JSON.stringify(errors, null, 4)}</pre>*/}
            </Form>
          </DialogContent>

          <DialogActions>
            <Button disabled={loading} onClick={() => handleClose(handleReset)}>
              {t('Cancelar', { ns: 'maxion' })}
            </Button>
            <Button
              type="submit"
              onClick={() => handleSubmit()}
              disabled={isSubmitting}
            >
              {loading
                ? t('Enviando', { ns: 'maxion' })
                : t('Enviar', { ns: 'maxion' })}
            </Button>
          </DialogActions>

          {loading && <Loading />}
        </Dialog>
      )}
    </Formik>
  );
}

export default withTranslation('maxion')(ModalUpload);

import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import axios from '../../../../../../utils/axios';
import { POContext } from '../../../../../../contexts/POContext';
import useAuth from '../../../../../../hooks/useAuth';
import { checkPermission } from '../../../../../../utils/permissions';
import { withTranslation } from 'react-i18next';
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
} from '@mui/material';

import TableCalculationVehicles from './components/TableCalculationVehicles';
import Loading from '../../../../../../components/Loading';
import TablesBySegments from './components/TablesBySegments';
import ModalRecalculate from '../ModalRecalculate';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;
      font-size: 13.5px;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  .info-plano {
    margin: 40px 0;
    padding: 0 20px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      width: 80%;
      justify-content: space-between;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }

  .observations {
    flex: 1 1 auto;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    min-width: 0;
  }

  .workDays-and-vehicles {
    position: relative;
    margin: 40px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .label {
      display: block;
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
      margin-bottom: 10px;
    }
  }
`;

const SaveButton = styled(Button)`
  width: 150px;
  height: 38px;
  margin-left: 40px;
  margin-top: 15px;
`;

const BoxAdjustmentNextYear = styled.div`
  margin-top: 120px;

  h2 {
    margin-bottom: 20px;
  }
`;

const initialValuesCalendar = [
  { code: 'm00', nome: 'Janeiro', value: '' },
  { code: 'm01', nome: 'Fevereiro', value: '' },
  { code: 'm02', nome: 'Março', value: '' },
  { code: 'm03', nome: 'Abril', value: '' },
  { code: 'm04', nome: 'Maio', value: '' },
  { code: 'm05', nome: 'Junho', value: '' },
  { code: 'm06', nome: 'Julho', value: '' },
  { code: 'm07', nome: 'Agosto', value: '' },
  { code: 'm08', nome: 'Setembro', value: '' },
  { code: 'm09', nome: 'Outubro', value: '' },
  { code: 'm10', nome: 'Novembro', value: '' },
  { code: 'm11', nome: 'Dezembro', value: '' },
];

const initialValuesSegments = [
  {
    nome: 'Caminhões',
    code: 'Z02',
    value: '',
  },
  {
    nome: 'Ônibus',
    code: 'Z01',
    value: '',
  },
  {
    nome: 'Pickups',
    code: 'Z04',
    value: '',
  },
];

function formateDate(dateIso) {
  const date = parseISO(dateIso);
  const newDate = format(date, 'dd/MM/yyyy HH:mm');
  return newDate;
}

function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate();
}

function AsyncText({ loading, children }) {
  return loading ? (
    <div style={{ padding: '3px' }}>
      <CircularProgress size={10} />
    </div>
  ) : (
    <React.Fragment>{children}</React.Fragment>
  );
}

function AnaliseDeMercado({ t }) {
  const [infoVersion, setInfoVersion] = useState({});
  const [customer, setCustomer] = useState({});
  const [initialValuesFormik, setInitialValuesFormik] = useState({
    calendar: initialValuesCalendar,
    segments: initialValuesSegments,
  });
  const [loadingFetchVersion, setLoadingFetchVersion] = useState(true);
  const [loadingFetchCustomer, setLoadingFetchCustomer] = useState(true);
  const [loadingFetchParams, setLoadingFetchParams] = useState(true);
  const [refreshTablesBySegment, setRefreshTablesBySegment] = useState(false);

  const [isPlanActive, setIsPlanActive] = useState(false);

  const [openModalRecalculate, setOpenModalRecalculate] = useState(false);

  const {
    customerId,
    versionId,
    setVersionId,
    setInvisibleNumberWithCheck,
    customer: customerContext,
    setCustomer: setCustomerContext,
    isEditingAop,
  } = useContext(POContext);
  const { user } = useAuth();

  const finishedAop = customerContext && customerContext.aop === 'finished';
  const userHasPermissionToManage = user && checkPermission(user, [], [9, 10]);
  const isDisabledManage =
    !userHasPermissionToManage || !isPlanActive || finishedAop;

  const validationSchemaFormik = Yup.object().shape({
    calendar: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .typeError(t('Formato inválido', { ns: 'maxion' }))
          .min(0, t('Quantidade inválida'))
          .test({
            name: 'max',
            message: t('Quantidade inválida'),
            test: (value, context) =>
              value === undefined ? true : value <= context.parent.maxDays,
          })
          .test('is-integer', t('Formato inválido'), (value) =>
            value === undefined ? true : Number.isInteger(value)
          ),
      })
    ),
    segments: Yup.array().of(
      Yup.object().shape({
        value: Yup.number()
          .typeError(t('Formato inválido', { ns: 'maxion' }))
          .test('is-integer', t('Formato inválido'), (value) =>
            value === undefined ? true : Number.isInteger(value)
          ),
      })
    ),
  });

  const fetchInfosVersion = useCallback(async () => {
    try {
      setLoadingFetchVersion(true);

      const { data: version } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_versions/${versionId}`
      );

      if (!version.final_version && version.deleted_at === null) {
        setIsPlanActive(true);
        toast.info(t('Plano operacional ativo', { ns: 'maxion' }));
      } else {
        toast.info(t('Plano operacional inativo', { ns: 'maxion' }));
        setIsPlanActive(false);
      }

      const splitBaseDate = version.base_date.split('-');
      const yearNumber = parseInt(splitBaseDate[0]);
      const monthNumber = parseInt(splitBaseDate[1]);
      // const monthAdjusted = monthNumber - 1;
      const prevision = 12 - monthNumber;
      const base = `${monthNumber}+${prevision}`;

      const newObj = {
        ...version,
        base: base,
        year: yearNumber + 1,
      };

      setInfoVersion(newObj);
      setLoadingFetchVersion(false);
    } catch (error) {
      console.log(error);
      setLoadingFetchVersion(true);
      toast.error(t('Erro ao buscar versão', { ns: 'maxion' }));
    }
  }, [t, versionId]);

  const fetchCustomer = useCallback(async () => {
    try {
      setLoadingFetchVersion(true);

      const { data: customer } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}criticas/grupos_clientes/${customerId}`
      );

      setCustomer(customer);
      setLoadingFetchCustomer(false);
    } catch (error) {
      console.log(error);
      setLoadingFetchCustomer(true);
      toast.error(t('Erro ao buscar cliente', { ns: 'maxion' }));
    }
  }, [t, customerId]);

  useEffect(() => {
    if (versionId && customerId) {
      Promise.all([fetchCustomer(), fetchInfosVersion()]).catch((error) => {
        toast.error(
          t('Erro ao buscar informações do cliente ou versão', {
            ns: 'maxion',
          })
        );
      });
    }
  }, [fetchCustomer, fetchInfosVersion, versionId, customerId, t]);

  const fetchParameters = useCallback(async () => {
    try {
      setLoadingFetchParams(true);

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_API}po_version_parameters/version-id/${infoVersion.id}/grupo-cliente/${customer.sk}`
      );

      const valuesCalendar = data?.calendar || {};
      const valuesSegments = data?.segments || [];

      const newArrayCalendar = initialValuesCalendar.map((item, index) => {
        const newValue =
          valuesCalendar[item.code] !== undefined &&
          valuesCalendar[item.code] !== null
            ? valuesCalendar[item.code]
            : '';

        return {
          ...item,
          value: newValue,
          maxDays: getDaysInMonth(infoVersion.year, index + 1),
        };
      });

      const objSegments = valuesSegments.reduce((acumulador, item) => {
        acumulador[item.code_segment] = item.quantity;
        return acumulador;
      }, {});

      const newArraySegments = initialValuesSegments.map((item) => {
        const newValue =
          objSegments[item.code] !== undefined &&
          objSegments[item.code] !== null
            ? objSegments[item.code]
            : '';

        return {
          ...item,
          value: newValue,
        };
      });

      setInitialValuesFormik({
        calendar: newArrayCalendar,
        segments: newArraySegments,
      });
    } catch (error) {
      console.log(`error submiting data: ${error}`);
    } finally {
      setLoadingFetchParams(false);
    }
  }, [customer.sk, infoVersion.id, infoVersion.year]);

  useEffect(() => {
    if (
      Object.keys(infoVersion).length > 0 &&
      Object.keys(customer).length > 0
    ) {
      fetchParameters();
    }
  }, [fetchParameters, infoVersion, customer]);

  async function handleCreate(values) {
    try {
      const body = {
        version_id: infoVersion.id,
        sk_grupo_cliente: customer.sk,
        calendar: values.calendar.reduce((acumulador, item) => {
          acumulador[item.code] =
            item.value === '' ? null : parseInt(item.value);
          return acumulador;
        }, {}),
        segments: values.segments.map((item) => {
          return {
            code_segment: item.code,
            quantity: item.value === '' ? null : parseInt(item.value),
          };
        }),
      };

      await axios.post(
        `${process.env.REACT_APP_PUBLIC_API}po_version_parameters`,
        body
      );

      toast.success(
        t('Parametrização atualizada com sucesso', { ns: 'maxion' })
      );

      fetchParameters();
      setRefreshTablesBySegment(true);
    } catch (error) {
      toast.error(t('Erro ao enviar parametrização', { ns: 'maxion' }));
      console.log(`Error submiting data: ${error}`);
    }
  }

  const formik = useFormik({
    initialValues: initialValuesFormik,
    onSubmit: handleCreate,
    validationSchema: validationSchemaFormik,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = formik;

  return (
    <div style={{ width: '100%' }}>
      <Helmet title={t('Análise de Mercado', { ns: 'maxion' })} />

      <Infobar t={t}>
        <div>
          <div>
            <h2> {t('Análise de Mercado', { ns: 'maxion' })}</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Plano Operacional', { ns: 'maxion' })}</p>

              <button
                onClick={() => {
                  setVersionId(null);
                  setInvisibleNumberWithCheck(0);
                }}
              >
                {t('Listagem de versões', { ns: 'maxion' })}
              </button>

              <button
                onClick={() => {
                  setCustomerContext(null);
                  setInvisibleNumberWithCheck(1);
                }}
              >
                {t('Plano operacional por grupo de cliente', { ns: 'maxion' })}
              </button>

              <span>{t('Análise de Mercado', { ns: 'maxion' })}</span>
            </Breadcrumbs>
          </div>

          <Divider className="divider" />
        </div>
      </Infobar>

      <BoxContent>
        <h2>{t('Plano operacional', { ns: 'maxion' })}</h2>

        <div className="info-plano">
          {openModalRecalculate && (
            <ModalRecalculate
              open={openModalRecalculate}
              setOpen={setOpenModalRecalculate}
              handleCreate={handleCreate}
              formik={formik}
              t={t}
            />
          )}
          <div className="flex">
            <div className="column">
              <div className="item">
                <p>{t('Cliente', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchCustomer}>
                  <b>
                    {customer.descricao_grupo_cliente ||
                      t('N/A', { ns: 'maxion' })}
                  </b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Data de criação', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>
                    {infoVersion.created_at
                      ? formateDate(infoVersion.created_at, 'dd/MM/yyyy HH:mm')
                      : t('N/A', { ns: 'maxion' })}
                  </b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Última atualização', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>
                    {infoVersion.updated_at
                      ? formateDate(infoVersion.updated_at, 'dd/MM/yyyy HH:mm')
                      : t('N/A', { ns: 'maxion' })}
                  </b>
                </AsyncText>
              </div>
            </div>

            <div className="column">
              <div className="item">
                <p>{t('Base', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{infoVersion.base || t('N/A', { ns: 'maxion' })}</b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Nome da versão', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{infoVersion.name || t('N/A', { ns: 'maxion' })}</b>
                </AsyncText>
              </div>

              <div className="item">
                <p>{t('Observações', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b className="observations">
                    {infoVersion.observations || t('N/A', { ns: 'maxion' })}
                  </b>
                </AsyncText>
              </div>
            </div>

            <div className="column">
              <div className="item">
                <p>{t('Ano', { ns: 'maxion' })}:</p>

                <AsyncText loading={loadingFetchVersion}>
                  <b>{infoVersion.year || t('N/A', { ns: 'maxion' })}</b>
                </AsyncText>
              </div>
            </div>
          </div>
        </div>

        <div className="workDays-and-vehicles">
          <div>
            <div className="label">{t('Dias úteis', { ns: 'maxion' })}:</div>

            <Grid container spacing={2} sx={{ flexWrap: 'wrap' }}>
              {values.calendar.map((item, index) => (
                <Grid item xs={1} key={index} sx={{ minWidth: '100px' }}>
                  <TextField
                    disabled={isDisabledManage}
                    fullWidth
                    size="small"
                    variant="outlined"
                    name={`calendar.${index}.value`}
                    margin="dense"
                    label={t(item.nome, { ns: 'maxion' })}
                    value={item.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.calendar &&
                      touched.calendar[index] &&
                      Boolean(errors.calendar && errors.calendar[index])
                    }
                    helperText={
                      touched.calendar &&
                      touched.calendar[index] &&
                      errors.calendar &&
                      errors.calendar[index] &&
                      errors.calendar[index].value
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </div>

          <div>
            <div className="label">{t('Veículos', { ns: 'maxion' })}:</div>

            <Grid container spacing={3} sx={{ flexWrap: 'wrap' }}>
              {values.segments.map((item, index) => (
                <Grid item xs={2} key={index} sx={{ minWidth: '100px' }}>
                  <TextField
                    disabled={isDisabledManage}
                    name={`segments.${index}.value`}
                    size="small"
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    label={t(item.nome, { ns: 'maxion' })}
                    value={item.value}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.segments &&
                      touched.segments[index] &&
                      Boolean(errors.segments && errors.segments[index])
                    }
                    helperText={
                      touched.segments &&
                      touched.segments[index] &&
                      errors.segments &&
                      errors.segments[index] &&
                      errors.segments[index].value
                    }
                  />
                </Grid>
              ))}

              <SaveButton
                disabled={isDisabledManage || isSubmitting}
                variant="contained"
                color="secondary"
                size="small"
                onClick={() => {
                  handleSubmit();
                }}
              >
                {isSubmitting
                  ? t('Enviando', { ns: 'maxion' })
                  : t('Enviar', { ns: 'maxion' })}
              </SaveButton>
            </Grid>
          </div>

          {loadingFetchParams && <Loading />}
        </div>

        <div style={{ margin: '60px 0' }}>
          <Divider />
        </div>

        <TablesBySegments
          t={t}
          versionId={infoVersion.id}
          customerId={customer.sk}
          versionYear={infoVersion.year}
          refreshTables={refreshTablesBySegment}
          onInnitialize={() => setRefreshTablesBySegment(false)}
          refreshTablesBySegment={refreshTablesBySegment}
        />

        <BoxAdjustmentNextYear>
          <h2>{t('Ajuste de mercado para o próximo ano', { ns: 'maxion' })}</h2>

          <TableCalculationVehicles
            t={t}
            fetchParameters={fetchParameters}
            isPlanActive={isPlanActive}
            versionId={infoVersion.id}
            customerId={customer.sk}
            refreshTables={refreshTablesBySegment}
            onInnitialize={() => setRefreshTablesBySegment(false)}
            setRefreshTablesBySegment={setRefreshTablesBySegment}
          />
        </BoxAdjustmentNextYear>
      </BoxContent>
    </div>
  );
}

export default withTranslation('maxion')(AnaliseDeMercado);

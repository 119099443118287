import React, { useContext, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet-async';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Divider, Breadcrumbs, Button, CircularProgress } from '@mui/material';

import ReportGeneralFilters from './components/ReportGeneralFilters';
import { ReportContext } from './components/ReportProvider';
import {
  maskitoNumberOptionsGenerator,
  maskitoParseNumber,
} from '@maskito/kit';

import _ from 'lodash';
import ModalReportMonth from './components/ModalReportMonth';
import TextFieldMaskNumberV2 from '../../../../../components/TextFieldMaskNumberV2';
import {
  formatNumberFloat,
  formatNumberLocale,
} from '../../../../../utils/formats';
import exportToExcel from './components/exportToExcel';

const Infobar = styled.div`
  h2 {
    color: ${({ theme }) => theme.colors.blue_300};
    font-size: 24.5px;
  }

  .breadcrumbs {
    display: flex;
    gap: 5px;
    margin-top: 13px;
    font-size: 13.5px;

    a {
      color: ${({ theme }) => theme.colors.gray_500};
      text-decoration: none;

      :hover {
        text-decoration: underline;
      }
    }

    button {
      background-color: transparent;
      outline: none;
      border: none;
      color: ${({ theme }) => theme.colors.gray_500};
      cursor: pointer;
      font-size: 13.5px;

      :hover {
        text-decoration: underline;
      }
    }

    p {
      color: ${({ theme }) => theme.colors.gray_500};
    }

    span {
      color: ${({ theme }) => theme.colors.blue_300};
    }
  }

  .divider {
    margin: 25px 0;
  }
`;

const BoxContent = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  .info-plano {
    margin: 40px 0;
    padding: 0 20px;

    .flex {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 20px;
      width: 80%;
      justify-content: space-between;
    }

    .column {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }

  .item {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px;

    p {
      font-size: 16px;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
    }

    b {
      color: ${({ theme }) => theme.colors.gray_300};
    }
  }

  .observations {
    flex: 1 1 auto;
    white-space: normal;
    word-wrap: break-word;
    overflow: visible;
    min-width: 0;
  }

  .workDays-and-vehicles {
    position: relative;
    margin: 40px 0;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .label {
      display: block;
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue_200};
      flex: 0 0 auto;
      margin-bottom: 10px;
    }
  }
`;

const BoxContentFilters = styled.div`
  width: 100%;
  background-color: #fff;
  padding: 25px 30px;
  color: rgba(0, 0, 0, 0.87);
  border-radius: 6px;
  box-shadow: rgb(50 50 93 / 3%) 0px 2px 5px -1px,
    rgb(0 0 0 / 5%) 0px 1px 3px -1px;

  h2 {
    font-size: 28px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.blue_200};
  }

  margin-bottom: 15px;
`;

function ReportGeneral({ t, invisibleNumber }) {
  const {
    rowsCustomer,
    isLoadingReport,
    columns,
    dataRows,
    columnsBase,
    columnsVersion,
    columnsSimulate,
    formikFilter,
  } = useContext(ReportContext);

  const [openModalReporMonth, setOpenModalReporMonth] = useState(false);

  useEffect(() => {
    document.title = t('Relatório', { ns: 'maxion' });
  }, []);

  useEffect(() => {
    if (invisibleNumber === 1) {
      formikFilter.submitForm();
    }
  }, [invisibleNumber]);

  const CellIcon = styled(TableCell)`
    width: 50px;
  `;

  const CellIconIntern = styled(TableCell)`
    width: 64px;
  `;

  const CellIconTotals = styled(TableCell)`
    width: 64px;
    background-color: #c2ddf0;
  `;

  const CellData = styled(TableCell)`
    width: 150px;
    text-align: center;
  `;

  const CellDataSimulate = styled(TableCell)`
    width: 190px;
    text-align: center;
  `;

  const CellCustomer = styled(TableCell)`
    width: 350px;
  `;

  const CellIconCollapsible = styled(TableCell)`
    width: 50px;
    background-color: #c2ddf0;
  `;

  const CellDataCollapsible = styled(TableCell)`
    width: 150px;
    background-color: #c2ddf0;
    text-align: center;
  `;

  const CellCustomerCollapsible = styled(TableCell)`
    width: 350px;
    background-color: #c2ddf0;
    color: #0783c2;
  `;

  function formatFloat(value) {
    return value ? maskitoParseNumber(value.toString(), ',') : 0;
  }

  const getTableContainerScrollPosition = () => {
    const container = document.querySelector('.table-container');
    if (!container) return { top: 0, left: 0 };
    return {
      top: container.scrollTop,
      left: container.scrollLeft,
    };
  };

  const setTableContainerScrollPosition = (position) => {
    const container = document.querySelector('.table-container');
    if (container) {
      container.scrollTop = position.top;
      container.scrollLeft = position.left;
    }
  };

  function Rows(props) {
    const { row, idx, idxOpen } = props;
    const [open, setOpen] = React.useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const { updateTotalValues } = useContext(ReportContext);

    const lastVersion = row[1][columnsVersion[columnsVersion.length - 1]];
    const beforeLastVersion =
      columnsVersion.length > 1
        ? row[1][columnsVersion[columnsVersion.length - 2]]
        : null;

    const [variationDetails, setVariationDetails] = useState({
      value_total: 0,
      volume_total: 0,
      work_days: 0,
      volume_per_day: 0,
      value_total_per_volume: 0,
      value_m_per_day: 0,
    });

    useEffect(() => {
      if (lastVersion) {
        setVariationDetails({
          value_total: row[1].simulate.value_total
            ? row[1].simulate.value_total
            : 0,
          volume_total: row[1].simulate.volume_total
            ? row[1].simulate.volume_total
            : 0,
          work_days: row[1].simulate.work_days ? row[1].simulate.work_days : 0,
          volume_per_day: row[1].simulate.volume_per_day
            ? row[1].simulate.volume_per_day
            : 0,
          value_total_per_volume: row[1].simulate.value_total_per_volume
            ? row[1].simulate.value_total_per_volume
            : 0,
          value_m_per_day: row[1].simulate.value_m_per_day
            ? row[1].simulate.value_m_per_day
            : 0,
        });
      }
    }, [lastVersion]);

    // calcula porcentagem entre lastVersion.value_total e beforeLastversion.value_total Se beforelastVersion igual a 0, forçar 0
    const totalDifferencePercentage =
      beforeLastVersion && lastVersion && beforeLastVersion['value_total'] > 0
        ? ((lastVersion['value_total'] - beforeLastVersion['value_total']) /
            beforeLastVersion['value_total']) *
          100
        : 0;

    const totalDifferenceTotal =
      row[1].simulate &&
      lastVersion &&
      lastVersion.value_total > 0 &&
      lastVersion
        ? ((row[1].simulate.value_total - lastVersion.value_total) /
            lastVersion.value_total) *
          100
        : 0;

    const calcTotals = (event, type) => {
      let cloneRowDetail = _.cloneDeep(variationDetails);
      const valueInput = event.target.numericValue;

      const copyDataRows = _.cloneDeep(dataRows);

      cloneRowDetail = {
        ...cloneRowDetail,
        value_total: valueInput,
        value_total_per_volume:
          valueInput / formatFloat(cloneRowDetail.volume_total),
        value_m_per_day:
          valueInput / 1000000 / formatFloat(cloneRowDetail.work_days),
      };

      copyDataRows[row[0]].simulate = cloneRowDetail;
      copyDataRows[row[0]].open = row[1].open || true;

      // row[1].simulate = cloneRowDetail;

      let totalValue = 0;
      Object.entries(copyDataRows).map((row) => {
        const itemSimulate = row[1].simulate;
        if (itemSimulate && row[0] !== 'TOTAL') {
          totalValue += itemSimulate.value_total;
        }
      });

      copyDataRows['TOTAL'].simulate.value_total = totalValue;
      setVariationDetails(cloneRowDetail);

      if (type === 'input') {
        return cloneRowDetail;
      } else if (type === 'blur') {
        return copyDataRows;
      }
    };

    const calcTotalsDetails = (value, rowDetail, type) => {
      let cloneRowDetail = _.cloneDeep(variationDetails);
      const valueInput = formatFloat(value);

      const copyDataRows = _.cloneDeep(dataRows);

      switch (rowDetail.key) {
        case 'volume_total':
          cloneRowDetail = {
            ...cloneRowDetail,
            volume_total: valueInput,
            value_total_per_volume:
              valueInput !== 0 ? cloneRowDetail.value_total / valueInput : 0,
            volume_per_day:
              formatFloat(cloneRowDetail.work_days) !== 0
                ? valueInput / cloneRowDetail.work_days
                : 0,
          };
          break;
        case 'work_days':
          cloneRowDetail = {
            ...cloneRowDetail,
            work_days: valueInput,
            volume_per_day:
              formatFloat(valueInput) !== 0
                ? cloneRowDetail.volume_total / valueInput
                : 0,
            value_m_per_day:
              valueInput !== 0
                ? cloneRowDetail.value_total / 1000000 / valueInput
                : 0,
          };
          break;
      }

      copyDataRows[row[0]].simulate = cloneRowDetail;
      copyDataRows[row[0]].open = row[1].open || true;

      setVariationDetails(cloneRowDetail);

      if (type === 'input') {
        return cloneRowDetail;
      } else if (type === 'blur') {
        return copyDataRows;
      }
    };

    return (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          {['TOTAL', 'OUTROS'].includes(row[0]) ? (
            <CellIconTotals />
          ) : (
            <CellIconCollapsible>
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </CellIconCollapsible>
          )}
          {/* Primeira linha referente ao cliente */}
          <CellCustomerCollapsible>
            {t(row[0].trim(), { ns: 'maxion' })}
          </CellCustomerCollapsible>
          {columnsBase.map((col, idxCol) => {
            const objValue = row[1][col];

            return (
              col !== '' && (
                <CellDataCollapsible
                  key={`${row.sk_grupo_cliente}-${col}-${idxCol}`}
                  className={`${idxCol === 0 ? 'border-left' : ''} ${
                    idxCol === columnsBase.length - 1 ? 'border-right' : ''
                  }`}
                >
                  {objValue &&
                    objValue['value_total'] &&
                    formatNumberLocale(objValue['value_total'], 2)}
                </CellDataCollapsible>
              )
            );
          })}
          {columnsVersion.map((col, idxCol) => {
            const objValue = row[1][col];

            return (
              col !== '' && (
                <CellDataCollapsible
                  key={`${row.sk_grupo_cliente}-${col}-${idxCol}`}
                  className={`${idxCol === 0 ? 'border-left' : ''} ${
                    idxCol === columnsVersion.length - 1 ? 'border-right' : ''
                  }`}
                >
                  {objValue &&
                    objValue['value_total'] &&
                    formatNumberLocale(objValue['value_total'], 2)}
                </CellDataCollapsible>
              )
            );
          })}
          <CellDataCollapsible>
            {Math.round(totalDifferencePercentage)}%
          </CellDataCollapsible>
          <CellDataCollapsible className={'border-left border-right'}>
            {!['TOTAL', 'OUTROS'].includes(row[0]) && (
              <TextFieldMaskNumberV2
                mask={{
                  precision: 2,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                }}
                style={{
                  backgroundColor: 'white',
                }}
                disabled={['TOTAL', 'OUTROS'].includes(row[0])}
                value={
                  lastVersion && variationDetails && isEditing
                    ? variationDetails.value_total
                    : row && row[1] && row[1].simulate.value_total
                }
                onBlur={(e) => {
                  const scrollPosition = getTableContainerScrollPosition();
                  const copyDataRows = calcTotals(e, 'blur');
                  updateTotalValues(copyDataRows);

                  if (isEditing) {
                    setIsEditing(false);
                  }
                  setTimeout(() => {
                    setTableContainerScrollPosition(scrollPosition);
                  }, 0);
                }}
                onInput={(e) => {
                  e.stopPropagation();
                  calcTotals(e, 'input');
                  if (!isEditing) {
                    setIsEditing(true);
                  }
                }}
              />
            )}
            {['OUTROS'].includes(row[0]) && (
              <p style={{ lineHeight: 3 }}>
                {formatNumberLocale(variationDetails.value_total, 2)}
                <br />
                {/*{totalValues}*/}
              </p>
            )}
            {['TOTAL'].includes(row[0]) && (
              <p style={{ lineHeight: 3 }}>
                {row &&
                  row[1] &&
                  formatNumberLocale(row[1].simulate.value_total, 2)}
              </p>
            )}
          </CellDataCollapsible>
          <CellDataCollapsible className={'border-right'}>
            {Math.round(totalDifferenceTotal)}%
          </CellDataCollapsible>
        </TableRow>
        {!['OUTROS', 'TOTAL'].includes(row[0]) && (
          <TableRow>
            <TableCell style={{ padding: 0 }} colSpan={columns.length + 1}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Table>
                  <TableBody>
                    {rowsCustomer &&
                      rowsCustomer.map((rowDetail, idxRowDetail) => {
                        let decimalPlaces = 2;

                        switch (rowDetail.key) {
                          case 'value_m_per_day':
                            decimalPlaces = 4;
                            break;
                          case 'value_total_per_volume':
                            decimalPlaces = 2;
                            break;
                          default:
                            decimalPlaces = 0;
                            break;
                        }

                        const totalDifferenceDetail =
                          beforeLastVersion &&
                          lastVersion &&
                          beforeLastVersion[rowDetail.key] > 0
                            ? ((lastVersion[rowDetail.key] -
                                beforeLastVersion[rowDetail.key]) /
                                beforeLastVersion[rowDetail.key]) *
                              100
                            : 0;

                        const totalDifferenceSimulate =
                          row[1].simulate &&
                          lastVersion &&
                          lastVersion[rowDetail.key] > 0
                            ? ((row[1].simulate[rowDetail.key] -
                                lastVersion[rowDetail.key]) /
                                lastVersion[rowDetail.key]) *
                              100
                            : 0;

                        return (
                          <TableRow
                            key={`${row.sk_grupo_cliente}-${rowDetail.key}-${idxRowDetail}`}
                          >
                            <CellIconIntern />
                            <CellCustomer>
                              {t(rowDetail.label, { ns: 'maxion' })}
                            </CellCustomer>
                            {columnsBase.map((col, idxCol) => {
                              const objValue = row[1][col];

                              return (
                                col !== '' && (
                                  <CellData
                                    key={`${row.sk_grupo_cliente}-${col}-${idxCol}`}
                                    className={`${
                                      idxCol === 0 ? 'border-left' : ''
                                    } ${
                                      idxCol === columnsBase.length - 1
                                        ? 'border-right'
                                        : ''
                                    }`}
                                  >
                                    {objValue &&
                                      objValue[rowDetail.key] &&
                                      formatNumberLocale(
                                        objValue[rowDetail.key],
                                        2
                                      )}
                                  </CellData>
                                )
                              );
                            })}
                            {columnsVersion.map((col, idxCol) => {
                              const objValue = row[1][col];

                              return (
                                col !== '' && (
                                  <CellData
                                    key={`${row.sk_grupo_cliente}-${col}-${idxCol}`}
                                    className={`${
                                      idxCol === columnsVersion.length - 1
                                        ? 'border-right'
                                        : ''
                                    }`}
                                  >
                                    {objValue &&
                                      objValue[rowDetail.key] &&
                                      formatNumberFloat(
                                        objValue[rowDetail.key]
                                      )}
                                  </CellData>
                                )
                              );
                            })}
                            <CellDataSimulate>
                              {Math.round(totalDifferenceDetail)}%
                            </CellDataSimulate>
                            <CellDataSimulate
                              className={'border-left border-right'}
                            >
                              <TextFieldMaskNumberV2
                                mask={{
                                  precision: decimalPlaces,
                                  decimalSeparator: ',',
                                  thousandSeparator: '.',
                                }}
                                style={{
                                  backgroundColor: 'white',
                                }}
                                value={
                                  lastVersion && variationDetails && isEditing
                                    ? variationDetails[rowDetail.key]
                                    : (row &&
                                        row[1] &&
                                        row[1].simulate[rowDetail.key]) ||
                                      0
                                }
                                disabled={[
                                  'volume_per_day',
                                  'value_total_per_volume',
                                  'value_m_per_day',
                                ].includes(rowDetail.key)}
                                onBlur={(e) => {
                                  const scrollPosition =
                                    getTableContainerScrollPosition();

                                  console.log('scrollPosition', scrollPosition);

                                  const copyDataRows = calcTotalsDetails(
                                    e.target.value,
                                    rowDetail,
                                    'blur'
                                  );
                                  updateTotalValues(copyDataRows);

                                  if (isEditing) {
                                    setIsEditing(false);
                                  }
                                  setTimeout(() => {
                                    setTableContainerScrollPosition(
                                      scrollPosition
                                    );
                                  }, 0);
                                }}
                                onInput={(e) => {
                                  calcTotalsDetails(
                                    e.target.value,
                                    rowDetail,
                                    'input'
                                  );

                                  if (!isEditing) {
                                    setIsEditing(true);
                                  }
                                }}
                              />
                            </CellDataSimulate>
                            <CellDataSimulate className={'border-right'}>
                              {Math.round(totalDifferenceSimulate)}%
                            </CellDataSimulate>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }

  const BoxContentMain = styled(BoxContent)`
    min-height: calc(100vh - 650px);
    padding: 10px;
    width: 100%;
  `;

  const TableDiv = styled.div`
    width: 95%;

    .table-container {
      margin: 20px;
      height: 100vh !important;
      overflow-y: auto;
      width: 100%;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
    }

    table tr:last-child .border-bottom {
      border-bottom: 2px solid #0783c2;
    }

    th {
      color: #0783c2;
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    th,
    td {
      padding: 5px;
      text-align: center;
      font-size: 14px;
    }

    thead th {
      background-color: #c2ddf0;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .row-title th {
      background-color: #f4f7fc;
      position: -webkit-sticky;
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 20px !important;
    }

    .row-collapsible {
      background-color: #0783c2;
    }

    tbody tr:nth-child(even) {
      background-color: #f4f8fc;
    }

    .border-left {
      border-left: 2px solid #0783c2;
    }

    .border-right {
      border-right: 2px solid #0783c2;
    }

    .border-top {
      border-top: 2px solid #0783c2;
    }

    tr {
      line-height: 15px;
    }
  `;

  const handleExport = () => {
    exportToExcel(
      dataRows,
      columnsBase,
      columnsVersion,
      columnsSimulate,
      rowsCustomer,
      t
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <Helmet title={t('Visão geral do plano operacional', { ns: 'maxion' })} />

      <Infobar t={t}>
        <div>
          <div>
            <h2> {t('Visão geral do plano operacional', { ns: 'maxion' })}</h2>

            <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
              <NavLink to="/">{t('OpenMax', { ns: 'maxion' })}</NavLink>

              <p>{t('Números Maxion', { ns: 'maxion' })} </p>

              <p>{t('Plano Operacional', { ns: 'maxion' })}</p>

              <span>
                {t('Visão geral do plano operacional', { ns: 'maxion' })}
              </span>
            </Breadcrumbs>
          </div>

          <Divider className="divider" />
        </div>
      </Infobar>

      <BoxContentFilters>
        <ReportGeneralFilters t={t} isLoading={isLoadingReport} />
      </BoxContentFilters>

      <BoxContentMain>
        {isLoadingReport && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: 60,
            }}
          >
            <CircularProgress size={50} />
          </div>
        )}
        {!isLoadingReport && (
          <TableDiv>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                padding: 20,
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                mb={5}
                onClick={() => setOpenModalReporMonth(true)}
              >
                {t('Simular mensalmente', { ns: 'maxion' })}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
                style={{
                  marginLeft: 10,
                }}
              >
                {t('Exportar para Excel', { ns: 'maxion' })}
              </Button>
            </div>
            <TableContainer component={Paper} className={'table-container'}>
              <Table aria-label="collapsible table" stickyHeader>
                <TableHead>
                  <TableRow className={'row-title'}>
                    <CellIconIntern />
                    <CellCustomer>
                      {t('CLIENTES', { ns: 'maxion' })}
                    </CellCustomer>
                    {columnsBase &&
                      columnsBase.length > 0 &&
                      columnsBase.map((column, idxCol) => (
                        <CellData
                          component="th"
                          key={`${column}-${idxCol}`}
                          className={`${idxCol === 0 ? 'border-left' : ''} ${
                            idxCol === columnsBase.length - 1
                              ? 'border-right'
                              : ''
                          } border-top`}
                        >
                          {t(column, { ns: 'maxion' })}
                        </CellData>
                      ))}
                    {columnsVersion &&
                      columnsVersion.length > 0 &&
                      columnsVersion.map((column, idxCol) => (
                        <CellData
                          component="th"
                          key={`${column}-${idxCol}`}
                          className={`${
                            idxCol === columnsVersion.length - 1
                              ? 'border-right'
                              : ''
                          } border-top`}
                        >
                          {column}
                        </CellData>
                      ))}
                    {columnsSimulate &&
                      columnsSimulate.length > 0 &&
                      columnsSimulate.map((column, idxCol) => (
                        <CellDataSimulate
                          component="th"
                          key={`${column}-${idxCol}`}
                          className={`border-right border-top`}
                        >
                          {column}
                        </CellDataSimulate>
                      ))}
                  </TableRow>
                </TableHead>
                <tbody>
                  {dataRows &&
                    Object.entries(dataRows).length > 0 &&
                    Object.entries(dataRows).map((row, idxRow) => (
                      <Rows key={`row-tb-${idxRow}`} row={row} idx={idxRow} />
                    ))}
                </tbody>
              </Table>
              {/*</div>*/}
            </TableContainer>
          </TableDiv>
        )}
      </BoxContentMain>
      {
        <ModalReportMonth
          t={t}
          openModal={openModalReporMonth}
          setOpenModal={setOpenModalReporMonth}
        />
      }
    </div>
  );
}

export default withTranslation('maxion')(ReportGeneral);

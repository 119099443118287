import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions as DialogActionsMUI,
  DialogContent as DialogContentMUI,
  DialogContentText,
  DialogTitle as DialogTitleMUI,
  Divider,
  IconButton,
} from '@mui/material';
import { POContext } from '../../../../../contexts/POContext';

const DialogTitle = styled(DialogTitleMUI)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  padding: 12px 20px;
`;

const DialogContent = styled(DialogContentMUI)`
  padding: 25px 20px;
`;

const DialogActions = styled(DialogActionsMUI)`
  background-color: #f6f9fc;
`;

const Input = styled.input`
  background-color: #fff !important;
  margin-right: 10px;
  padding: 6px 8px !important;
  min-width: 200px;
  outline: none;
  border: 1px solid #ccc;
  border-radius: 0.2rem;
  color: ${({ theme }) => theme.colors.gray_500};

  ::placeholder {
    color: #9a9a9a;
    font-weight: 400;
  }
`;

export default function ModalRecalculate({ open, setOpen, formik, t }) {
  const { i18n } = useTranslation();

  const [valueInput, setValueInput] = useState('');
  const [loading, setLoading] = useState(false);
  const { customer, setCustomer } = useContext(POContext);

  const key = i18n.language === 'pt-BR' ? 'CONFIRMAR' : 'CONFIRM';

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        {t('Recalcular análise', { ns: 'maxion' })}
        <IconButton
          onClick={() => setOpen(false)}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <CloseRoundedIcon />
        </IconButton>
      </DialogTitle>

      <Divider />

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(
            'Esta ação irá descartar a análise de mercado salva anteriormente',
            {
              ns: 'maxion',
            }
          )}
          .
          <br />
          <b style={{ color: '#FF0000' }}>
            {t('Isto não poderá ser desfeito', { ns: 'maxion' })}.
          </b>
        </DialogContentText>
      </DialogContent>

      <Divider />

      <DialogActions>
        <Input
          placeholder={`${t('Digite a palavra', { ns: 'maxion' })} ${key}`}
          onChange={(e) => setValueInput(e.target.value)}
          value={valueInput}
        />

        <Button
          color="primary"
          autoFocus
          disabled={loading || valueInput !== key}
          onClick={() => {
            setOpen(false);
            formik.handleSubmit();
            const newCustomer = { ...customer, aop: 'pending' };
            setCustomer(newCustomer);
          }}
        >
          {loading ? (
            <CircularProgress size="16px" />
          ) : (
            t('Confirmar', { ns: 'maxion' })
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import React, { useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { withTranslation } from 'react-i18next';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  marginTop: '30px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

function StyledDropzone({
  setFieldValue,
  setFormData,
  t,
  isEditing = false,
  isMargin = false,
  setIsMargin = null,
  accept = null,
}) {
  // Função para preparar o objeto de aceitação de arquivos
  const prepareAcceptConfig = (acceptProp) => {
    if (!acceptProp) return {};

    // Se for uma string única, converte para array
    const types = Array.isArray(acceptProp) ? acceptProp : [acceptProp];

    // Cria o objeto de configuração de aceitação
    return types.reduce((acc, type) => {
      // Mapeia tipos comuns para seus MIME types correspondentes
      const mimeTypes = {
        pdf: { 'application/pdf': [] },
        image: { 'image/*': [] },
        excel: {
          'application/vnd.ms-excel': [],
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            [],
        },
        word: {
          'application/msword': [],
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
            [],
        },
        csv: { 'text/csv': [] },
      };

      // Se for um tipo predefinido, usa o mapeamento
      if (mimeTypes[type]) {
        return { ...acc, ...mimeTypes[type] };
      }

      // Se for um MIME type personalizado
      return { ...acc, [type]: [] };
    }, {});
  };

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: prepareAcceptConfig(accept),
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file, index) => {
        const split = file.name.split('.');
        const name = split[0];
        const extension = split[split.length - 1];
        const newName =
          name +
          '_' +
          Math.random().toString(36).substring(2, 12) +
          `.${extension}`;
        setFieldValue('url', `${newName}`);
        const renamedFile = new File([file], `${newName}`, { type: file.type });
        const fileFormData = new FormData();
        fileFormData.append('file', renamedFile);

        return setFormData(fileFormData);
      });
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const acceptedFileItems = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <p>
          {isEditing
            ? t(
                'Caso queira substituir o arquivo arraste o novo arquivo até aqui ou clique para selecionar',
                { ns: 'maxion' }
              )
            : t('Arraste o arquivo até aqui ou clique para selecionar', {
                ns: 'maxion',
              })}
        </p>
      </div>
      {acceptedFileItems.length > 0 && setIsMargin
        ? setIsMargin(false)
        : setIsMargin(true)}
      {acceptedFileItems.length > 0 && (
        <aside style={{ padding: 20 }}>{acceptedFileItems}</aside>
      )}
    </div>
  );
}

export default withTranslation('maxion')(StyledDropzone);
